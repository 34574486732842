import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../services/index";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Reducers/index";
import "./style.css";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";

//import {AccountCircleIcon,PowerSettingsNew,ArrowRightIcon,DashboardIcon,MenuIcon,CloseIcon  } from '@material-ui/icons';

import {
  APPLICANT_ROUTES,
  CO_APPLICANT_ROUTES,
} from "../../services/applicationConstants";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

function Index(props: any) {
  const [open, setOpen] = React.useState(false);

  return (
    <div className={open ? "openside sidenav" : "notopenside sidenav"}>
      <button
        className="toggleNav MenuIcon"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <MenuIcon />
      </button>
      <button
        className="toggleNav CloseIcon"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <CloseIcon />
      </button>
      <div className="sideInNav">
        <div className={open ? "open" : "notopen"}>
          <h2 className="siteNameNav">Super Admin</h2>
          <nav>
            <ul className="navList">
              <li>
                <Link to={ROUTES.Dashboard}>
                  <DashboardIcon /> &nbsp;Vendors Info{" "}
                  <span>
                    <ArrowRightIcon />
                  </span>{" "}
                </Link>
              </li>
              <li>
                <Link to={ROUTES.VendorRegister}>
                  <PersonAdd /> &nbsp; Add Vendors{" "}
                  <span>
                    <ArrowRightIcon />
                  </span>{" "}
                </Link>
              </li>
              <li>
                <Link to={ROUTES.FinanceApiUser}>
                  <AccountCircleIcon /> &nbsp; Finance API User{" "}
                  <span>
                    <ArrowRightIcon />
                  </span>{" "}
                </Link>
              </li>

              <li>
                <Link to={ROUTES.Reports}>
                  <AccountCircleIcon /> &nbsp; Reports{" "}
                  <span>
                    <ArrowRightIcon />
                  </span>{" "}
                </Link>
              </li>

              <li>
                <a
                  onClick={() => {
                    localStorage.clear();
                    props.history.push("/login");
                  }}
                >
                  <PowerSettingsNew /> &nbsp;Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Index);
