const companyUrl: string = "https://approveme.biz/pi/applicant?_vendorID=";
// const companyUrl: string = "http://3.83.17.231/pi/applicant?_vendorID=";

const ROUTES: any = {
  Dashboard: "/dashboard",
  VendorRegister: "/superadmin/addVender",
  SuperAdminLogin: "/superadmin",
  ForgetPassword: "/forgetPassword",
  ResetPassword: "/reset-password",
  FinanceApiUser: "/financeApiUser",
  ChangeVendorPassword: "/changeVendorPassword",
  ApplicantDetails: "/userDetails/:id",
  EditVendor: "/superadmin/vendor/edit",
  Reports: "/superadmin/reports",
};

const APPLICANT_ROUTES: any = {
  LoanInformation: "/loanInformation/applicant",
  PersonalInformation: "/personalInformation/applicant",
  IncomeInformation: "/incomeInformation/applicant",
  EmploymentInformation: "/employmentInformation/applicant",
  AddressInformation: "/addressInformation/applicant",
};
const CO_APPLICANT_ROUTES: any = {
  PersonalInformation: "/personalInformation/co-applicant",
  IncomeInformation: "/incomeInformation/co-applicant",
  EmploymentInformation: "/employmentInformation/co-applicant",
  AddressInformation: "/addressInformation/co-applicant",
};

export { ROUTES, APPLICANT_ROUTES, CO_APPLICANT_ROUTES, companyUrl };
