import React, { useState } from "react";
// import SideNav from '../../components/sideNav';
import "../index.css";
import Table from "../../components/Table/Table";
import Dropdown from "../../components/DropDown/DropDown";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  getAdminUserDetailsAction,
  getAdminFailureAction,
  getAdminSuccessAction,
  getAllVendors,
  changeVendorStatus,
} from "../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Reducers";
import { CLEAR_ALL_VENDORS } from "../../redux/types";
import { ROUTES } from "../../services";

enum EVendorTypes {
  Active = "active",
  Inactive = "inactive",
  All = "all",
}

export default function Index(props: any) {
  const rowLimit: number = 10;
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [category, setCategory] = useState<string>("ALL");
  const [vendorView, setVendorView] = useState<string>("active");

  const applicantData = useSelector((state: any) => {
    return state.AuthReducer.applicantData;
  });

  const allVendors = useSelector((state: any) => {
    return state.AuthReducer.allVendors;
  });

  console.log("allVendors", allVendors);
  const loginUserData = JSON.parse(localStorage.getItem("userData") || "{}")
    .user?.userId;
  React.useEffect(() => {
    // dispatch(getAdminUserDetailsAction(0));
    // dispatch({ type: CLEAR_ALL_VENDORS });
    console.log("USEE", loginUserData);
    dispatch(getAllVendors(0, loginUserData));
  }, []);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(allVendors?.count / rowLimit); i++) {
    pageNumbers.push(i);
  }

  const onChangePage = (pageNumber: number) => {
    if (pageNumber !== page) {
      setPage(pageNumber);
      dispatch(getAllVendors(pageNumber * rowLimit - rowLimit, loginUserData));
    }
  };
  const onClickViewDetails = (_id: string) => {
    props.history.push("/userDetails/" + _id);
  };

  const handleVendorViewChange = (type: EVendorTypes) => {
    console.log("type", type);
    setVendorView(type);
  };

  return (
    <div className="mainAdminDiv">
      <div className="borderDiv">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <h3>Vendors Info</h3>
          <Dropdown
            name="ss"
            label="Select Vendor Type"
            handleChange={(e: any) => {
              handleVendorViewChange(e.target.value);
            }}
            value={vendorView}
            itemId={"itemId"}
            itemLabel={"itemLabel"}
            menuItems={[
              {
                itemId: EVendorTypes.All,
                itemLabel: "All",
              },
              {
                itemId: EVendorTypes.Active,
                itemLabel: "Active",
              },
              {
                itemId: EVendorTypes.Inactive,
                itemLabel: "Inactive",
              },
            ]}
          />
        </div>

        <div className="dashbrdPage">
          <Table
            data={
              vendorView === "all"
                ? allVendors
                : vendorView === "active"
                ? {
                    ...allVendors,
                    data: allVendors?.data?.filter(
                      (vendor: any) => vendor["isActive"] === 1
                    ),
                  }
                : vendorView === "inactive"
                ? {
                    ...allVendors,
                    data: allVendors?.data?.filter(
                      (vendor: any) => vendor["isActive"] === 0
                    ),
                  }
                : []
            }
            onClickViewDetails={onClickViewDetails}
            editOption
            handleEdit={(vendor: any) => {
              console.log("vendor", vendor);

              props.history.push(`${ROUTES.EditVendor}/${vendor.userId}`);
            }}
            deleteOption
            handleDelete={(vendor: any) => {
              console.log("vendor to delete", vendor);
              dispatch(
                changeVendorStatus({
                  userId: vendor.userId,
                  isActive: vendor.isActive,
                })
              );
            }}
            reportOption
            handleReportPress={(vendor: any) => {
              console.log("vendor", vendor);

              props.history.push(`${ROUTES.Reports}/${vendor.userId}`);
            }}
          />
        </div>
        <div className="paginationDiv">
          {/* <div>
            <ArrowBackIcon />
          </div> */}
          {pageNumbers?.length > 0 &&
            pageNumbers.map((pageNumber: number, i: number) => {
              return (
                <div
                  className={pageNumber === page ? "buttonActive" : ""}
                  onClick={() => onChangePage(pageNumber)}
                  key={i}
                >
                  {pageNumber}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
