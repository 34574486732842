import { useEffect, useState } from "react";

import "./style.css";
// import Logoimg from "../../img/logo.png";
import { validateLogIn } from "./Validate";
// import { LoginIProps } from "../../services/interfaces";
import { signUpAction } from "../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FileCopy } from "@material-ui/icons";
import { copyToClipboard, companyUrl } from "../../services/index";
import { CLEAR_VENDOR_REGISTERED_DATA } from "../../redux/types";

interface LoginIProps {
  email: string | undefined;
  password: string | undefined;
  firstName: string | undefined;
  lastName?: string | undefined;
  companyName: string | undefined;
  vendorApiSfcCompanyCode: string | undefined;
  websiteUniqueUrl?: string | undefined;
}
interface ValidateForm {
  isFormValid: boolean;
  error: LoginIProps;
}
export default function Index(props: any) {
  const dispatch = useDispatch();
  const [state, setState] = useState<LoginIProps | undefined>(undefined);
  const [error, setError] = useState<LoginIProps | null>(null);
  const { vendorRegisteredData } = useSelector((state: any) => {
    return state.AuthReducer;
  });

  useEffect(() => {
    dispatch({ type: CLEAR_VENDOR_REGISTERED_DATA });
  }, []);

  const loginUserData = JSON.parse(localStorage.getItem("userData") || "{}")
    .user?.userId;

  console.log("vendorRegisteredData", vendorRegisteredData);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let response: ValidateForm = await validateLogIn(state);

    if (response.isFormValid) {
      await dispatch(signUpAction(state, props, loginUserData));

      setState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        companyName: "",
        vendorApiSfcCompanyCode: "",
        websiteUniqueUrl: "",
      });

      setError({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        companyName: "",
        vendorApiSfcCompanyCode: "",
        websiteUniqueUrl: "",
      });
    } else {
      setError(response.error);
    }
    // console.log("response", response, state);
  };

  const onHandleChange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };
  const newCompanyUrl = companyUrl + vendorRegisteredData._vendorID;
  return (
    <div className="commnbg1">
      <div className="formwidth signupform loginform">
        <h3> Add Vendors</h3>
        <form>
          <div className="formItem half">
            <div className="form-group">
              <label className="input__label">
                First Name<sup>*</sup>
                <input
                  className="inptField"
                  name="firstName"
                  placeholder="First Name"
                  onChange={onHandleChange}
                  value={state?.firstName}
                ></input>
              </label>
              {error?.firstName && <p>{error?.firstName}</p>}
            </div>
            {/* {error?.fname && <p>{error?.fname}</p>} */}

            <div className="form-group">
              <label className="input__label">
                Last Name
                <input
                  className="inptField"
                  name="lastName"
                  value={state?.lastName}
                  placeholder="Last Name"
                  onChange={onHandleChange}
                ></input>
              </label>
            </div>
            {/* {error?.lname && <p>{error?.lname}</p>} */}
          </div>
          <div className="formItem half">
            <div className="form-group">
              <label className="input__label">
                Email<sup>*</sup>
                <input
                  className="inptField"
                  name="email"
                  placeholder="Email"
                  value={state?.email}
                  onChange={onHandleChange}
                ></input>
              </label>
              {error?.email && <p>{error?.email}</p>}
            </div>
            {/* {error?.email && <p>{error?.email}</p>} */}

            <div className="form-group">
              <label className="input__label">
                Password<sup>*</sup>
                <input
                  name="password"
                  className="inptField"
                  type="password"
                  placeholder="Enter Password"
                  value={state?.password}
                  onChange={onHandleChange}
                ></input>
              </label>
              {error?.password && <p>{error?.password}</p>}
            </div>
          </div>
          <div className="formItem">
            <div className="form-group">
              <label className="input__label">
                Vendor Name<sup>*</sup>
                <input
                  name="companyName"
                  className="inptField"
                  type="text"
                  placeholder="Vendor Name"
                  onChange={onHandleChange}
                  value={state?.companyName}
                ></input>
              </label>
              {error?.companyName && <p>{error?.companyName}</p>}
            </div>
            {/* {error?.company && <p>{error?.company}</p>} */}
          </div>

          <div className="formItem">
            <div className="form-group">
              <label className="input__label">
                Vendor API SFC Company Code<sup>*</sup>
                <input
                  name="vendorApiSfcCompanyCode"
                  className="inptField"
                  type="text"
                  placeholder="SFC Company Code"
                  value={state?.vendorApiSfcCompanyCode}
                  onChange={onHandleChange}
                ></input>
              </label>
              {error?.vendorApiSfcCompanyCode && (
                <p>{error?.vendorApiSfcCompanyCode}</p>
              )}
            </div>
          </div>

          <div className="formItem">
            <div className="form-group">
              <label className="input__label">
                Website URL
                <input
                  name="websiteUniqueUrl"
                  className="inptField"
                  type="text"
                  placeholder="Website URL"
                  value={state?.websiteUniqueUrl}
                  onChange={onHandleChange}
                ></input>
              </label>
            </div>
          </div>
          <button className="submitBtn" onClick={handleSubmit}>
            Add Vendor{" "}
          </button>
          {Object.keys(vendorRegisteredData).length > 0 ? (
            <p>Vendor Added Succesfully </p>
          ) : null}
        </form>
        {Object.keys(vendorRegisteredData).length > 0 ? (
          <div className="vendorData">
            <div className="vendorDetailsTitle">
              <h3>Vendor Details</h3>
            </div>
            <span>
              Vendor Name: &nbsp;{" "}
              <span>{vendorRegisteredData.companyName}</span>{" "}
            </span>
            <span>
              First Name: &nbsp;<span>{vendorRegisteredData.firstName}</span>
            </span>
            <span>
              Last Name: &nbsp;<span>{vendorRegisteredData?.lastName}</span>
            </span>
            <span>
              Email: &nbsp;<span>{vendorRegisteredData.email}</span>
            </span>
            <span>
              Vendor API Company SFC Code: &nbsp;
              <span>{vendorRegisteredData.vendorApiSfcCompanyCode}</span>
            </span>
            <span>
              Vendor Unique URL: &nbsp;
              <span>
                {newCompanyUrl}{" "}
                <FileCopy
                  onClick={() => {
                    copyToClipboard(newCompanyUrl);
                  }}
                />
              </span>
            </span>
            <span>
              Embedded Code: &nbsp;
              <span>
                {` <iframe
              width="853"
              height="480"
            src="${newCompanyUrl}"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>`}
                <FileCopy
                  onClick={() => {
                    copyToClipboard(` <iframe
                  width="853"
                  height="480"
                src="${newCompanyUrl}"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>`);
                  }}
                />
              </span>
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
