import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    // width: "100%",
    height: "39px",
    margin: 0,
    "& .MuiOutlinedInput-root": {
      "& .MuiSelect-selectMenu": {
        textAlign: "left",
      },
    },
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  select: {
    height: "2em",
    borderRadius: "4px",
  },
}));
const Dropdown = ({
  label,
  name,
  handleChange,
  menuItems,
  itemLabel,
  value,
  itemId,
}) => {
  // console.log(
  //   "all in dropdown :>> ",
  //   label,
  //   name,

  //   menuItems,
  //   itemLabel,
  //   value
  // );
  console.log("value :>> ", value, menuItems);
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      size="small"
    >
      <select
        className={classes.select}
        name={name}
        value={value}
        onChange={handleChange}
        // defaultValue={defaultValue}
      >
        <option disabled selected value="null">
          {label}
        </option>

        {menuItems.length > 0 &&
          menuItems.map((item) => (
            <option value={item[itemId] || item}>
              {item[itemLabel] || item}
            </option>
          ))}
      </select>
    </FormControl>
  );
};
export default Dropdown;
