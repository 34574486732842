import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
        &copy; 2021 ELEVATE 
    </div>
  );
}

export default Footer;
