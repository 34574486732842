interface FormIProps {
  email: string | undefined;
  password: string | undefined;
}

export function validateLogIn(formData: FormIProps | undefined) {
  console.log("formData", formData);
  let isFormValid = true;
  let error: FormIProps = {
    email: "",
    password: "",
  };

  if (formData == undefined) {
    isFormValid = false;
    error.email = "This field cannot be empty!";
    error.password = "This field cannot be empty!";
  }
  if (
    formData &&
    (formData?.email == undefined ||
      formData?.email == "" ||
      formData?.email.trim().length == 0)
  ) {
    isFormValid = false;
    error.email = "Email cannot be empty!";
  } else if (
    formData &&
    formData.email !== undefined &&
    formData.email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = "Email must be a valid email address";
  }

  if (
    formData &&
    (formData?.password == undefined ||
      formData?.password == "" ||
      formData?.password.trim().length == 0)
  ) {
    isFormValid = false;
    error.password = "Password cannot be empty!";
  } else if (
    formData &&
    formData.password &&
    formData.password.trim().length > 0 &&
    (formData.password.trim().length < 6 ||
      formData.password.trim().length > 20)
  ) {
    isFormValid = false;
    error.password = "Password length must be between 6 to 15 characters";
  }

  console.log(error, "LOGIN FROM ERR");
  return {
    isFormValid,
    error,
  };
  // }
}
