interface FormIProps {
  email: string | undefined;
  newPassword: string | undefined;
  confirmPassword: string | undefined;
}

export function validateLogIn(formData: FormIProps | undefined) {
  console.log("formData", formData);
  let isFormValid = true;
  let error: FormIProps = {
    email: "",
    newPassword: "",
    confirmPassword: ""
  };

  if (formData == undefined) {
    isFormValid = false;
    error.email = "This field cannot be empty!";
  }
  if (
    formData &&
    (formData?.email == undefined ||
      formData?.email == "" ||
      formData?.email.trim().length == 0)
  ) {
    isFormValid = false;
    error.email = "Email cannot be empty!";
  } else if (
    formData &&
    formData.email !== undefined &&
    formData.email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = "Email must be a valid email address";
  }
  if (
    formData &&
    (formData?.newPassword == undefined ||
      formData?.newPassword == "" ||
      formData?.newPassword.trim().length == 0)
  ) {
    isFormValid = false;
    error.newPassword = "This field cannot be empty!";
  } 
  if (
    formData &&
    (formData?.confirmPassword == undefined ||
      formData?.confirmPassword == "" ||
      formData?.confirmPassword.trim().length == 0)
  ) {
    isFormValid = false;
    error.confirmPassword = "This field cannot be empty!";
  } 
  if (
    formData &&
    (formData?.confirmPassword !== formData?.newPassword)
  ) {
    isFormValid = false;
    error.confirmPassword = "Password do not match!";
  } 
  console.log(error, "LOGIN FROM ERR");
  return {
    isFormValid,
    error,
  };
  // }
}
