interface FormIProps {
  username: string | undefined;
  password: string | undefined;
}

export function validateLogIn(formData: FormIProps | undefined) {
  console.log("formData", formData);
  let isFormValid = true;
  let error: FormIProps = {
    username: "",
    password: "",
  };

  if (formData == undefined) {
    isFormValid = false;
    error.username = "This field cannot be empty!";
    error.password = "This field cannot be empty!";
  }
  if (
    formData &&
    (formData?.username == undefined ||
      formData?.username == "" ||
      formData?.username.trim().length == 0)
  ) {
    isFormValid = false;
    error.username = "username cannot be empty!";
  } 

  if (
    formData &&
    (formData?.password == undefined ||
      formData?.password == "" ||
      formData?.password.trim().length == 0)
  ) {
    isFormValid = false;
    error.password = "Password cannot be empty!";
  }
  console.log(error, "LOGIN FROM ERR");
  return {
    isFormValid,
    error,
  };
  // }
}
