import React, { useState } from "react";
// import SideNav from '../../components/sideNav';
import "../index.css";
import Table from "../../components/Table/Table";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { getUserDetails } from "../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Reducers";

export default function Index(props:any) {
  const rowLimit: number = 10;
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const [category, setCategory] = useState<string>("ALL");

  const userDetails = useSelector((state: any) => {
    return state.AuthReducer.userDetails;
  });
  React.useEffect(() => {
    dispatch(getUserDetails(props.match.params.id));
  }, []);

 

  return (
    <div className="mainAdminDiv">
      <div className="borderDiv">
        <h3>User Details</h3>
        <div className="dashbrdPage">
          {/* <Table data={applicantData} page={page}/> */}
          <div  dangerouslySetInnerHTML={{__html:  userDetails?.xmlResponse }}>

          </div>
        </div>
      
      </div>
    </div>
  );
}
