import { useState } from "react";

import "./Login.css";
// import Logoimg from "../../img/logo.png";
import { validateLogIn } from "./Validate";
// import { LoginIProps } from "../../services/interfaces";
import { updateVendorApiUser,getVendorApiUser } from "../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../services";
import { useEffect } from "react";

interface LoginIProps {
  username: string | undefined;
  password: string | undefined;
}

interface ValidateForm {
  isFormValid: boolean;
  error: LoginIProps;
}

const FinanceApiUser: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const userId =  JSON.parse(localStorage.getItem('userData') || '{}').user.userId;
  const [state, setState] = useState<any>();
  const [currentApiUsers, setCurrentApiUser] = useState<any>();
  const [error, setError] = useState<LoginIProps | null>(null);
console.log("currentApiUsers",currentApiUsers)
  useEffect(() => {
    (async()=>{
      const currentApiUser = await dispatch(getVendorApiUser(state,userId));
      setCurrentApiUser(currentApiUser)
    })()
    
  }, []);

  console.log('STATE',state)

  const onHandleChange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let response: ValidateForm = await validateLogIn(state);
    if (response.isFormValid) {
      setState(undefined);
      await dispatch(updateVendorApiUser(state,userId));
    
      const currentApiUser = await dispatch(getVendorApiUser(state,userId));
      setCurrentApiUser(currentApiUser)
    } else {
      setError(response.error);
    }
  };

  return (
    <div className="formwidth loginform">
      <form autoComplete='off'>
      <h3>Current Finance API User</h3>
      <p style={{color:'black'}}>Username: <span style={{color:'blue'}}>{currentApiUsers?.username}</span></p>
      <p style={{color:'black'}}>Password: <span style={{color:'blue'}}> {currentApiUsers?.password}</span></p>
        <h3>Update Finance API User</h3>
        <div className="formItem">
          <div className="form-group">
            <label className="input__label">
              API Username<sup>*</sup>
              <input
                className="inptField"
                name="username"
                placeholder="Username"
                onChange={onHandleChange}
                value={state?.username}
              ></input>
            </label>
            {error?.username && <p>{error?.username}</p>}
          </div>
        </div>
        <div className="formItem">
          <div className="form-group">
            <label className="input__label">
              API Password<sup>*</sup>
              <input
                name="password"
                className="inptField"
                type="password"
                placeholder="Enter Password"
                onChange={onHandleChange}
                value={state?.password}
              ></input>
            </label>
            {error?.password && <p>{error?.password}</p>}
          </div>
        </div>

        <button className="submitBtn" onClick={handleSubmit}>
          Update{" "}
        </button>
      </form>
    </div>
  );
};

export default FinanceApiUser;
