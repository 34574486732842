import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { copyToClipboard, companyUrl, ROUTES } from "../../services/index";
import { FileCopy } from "@material-ui/icons";
import "./Table.css";
import { Link } from "react-router-dom";

export default function BasicTable({
  data,
  onClickViewDetails,
  deleteOption,
  handleDelete,
  editOption,
  handleEdit,
  reportOption,
  handleReportPress,
}: any) {
  // const pageNumbers = [];
  // for (let i = 1; i <= Math.ceil(data.count / 2); i++) {
  //   pageNumbers.push(i);
  // }
  return (
    <TableContainer className="tableParent" component={Paper}>
      <Table className="table" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S.No.</TableCell>
            <TableCell>Vendor Name</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>SFC Code</TableCell>
            <TableCell>Vendor Unique URL</TableCell>
            <TableCell>Embedded Code</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.data && data.data.length > 0 ? (
            data.data.map((row: any, i: number) => {
              const newCompanyUrl = companyUrl + row._vendorID;
              return (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {String(i + 1)}
                  </TableCell>
                  <TableCell>
                    {row?.companyName ? row?.companyName : "-"}
                  </TableCell>
                  <TableCell>
                    {row?.firstName ? row?.firstName + " " : "-"}
                    {/* {row?.midName ? row?.midName + " " : ""} */}
                    {row?.lastName ? row?.lastName + " " : ""}
                  </TableCell>
                  <TableCell>{row?.email ? row?.email : "-"}</TableCell>
                  <TableCell>
                    {row?.vendorApiSfcCompanyCode
                      ? row?.vendorApiSfcCompanyCode
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {newCompanyUrl}
                    <br />
                    <FileCopy
                      onClick={() => {
                        copyToClipboard(newCompanyUrl);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {` <iframe
              width="853"
              height="480"
            src="${newCompanyUrl}"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>`}
                    <br />
                    <FileCopy
                      onClick={() => {
                        copyToClipboard(` <iframe
                      width="853"
                      height="480"
                    src="${newCompanyUrl}"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>`);
                      }}
                    />
                    <br />
                  </TableCell>

                  <TableCell>
                    {row?.createdAt
                      ? moment(row?.createdAt).format("MMMM Do YYYY")
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={{
                        pathname: ROUTES.ChangeVendorPassword,
                        state: {
                          options: row,
                        },
                      }}
                    >
                      Change Password
                    </Link>
                  </TableCell>
                  {deleteOption ? (
                    <TableCell>
                      <button onClick={() => handleDelete(row)}>
                        {row["isActive"] === 0 ? "Activate" : "Deactivate"}
                      </button>{" "}
                    </TableCell>
                  ) : null}

                  {editOption ? (
                    <TableCell>
                      <button onClick={() => handleEdit(row)}>Edit</button>{" "}
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })
          ) : (
            <TableRow>No Items to show</TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
