import { useEffect, useState } from "react";

import "./style.css";
// import Logoimg from "../../img/logo.png";
import { validateVendorUpdate } from "./Validate";
// import { LoginIProps } from "../../services/interfaces";
import {
  changeVendorStatus,
  getVendorDetails,
  signUpAction,
  updateVendorApiUser,
  updateVendorDetails,
} from "../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FileCopy } from "@material-ui/icons";
import { copyToClipboard, companyUrl } from "../../services/index";
import Loader from "../../components/Loader";
import { Switch } from "@material-ui/core";

interface IUpdateProps {
  email: string | undefined;
  firstName: string | undefined;
  lastName?: string | undefined;
  companyName: string | undefined;
  vendorApiSfcCompanyCode: string | undefined;
  websiteUniqueUrl?: string | undefined;
  enableWebsiteUrl?: true | false;
}
interface IValidateForm {
  isFormValid: boolean;
  error: IUpdateProps;
}
export default function EditVendor(props: any) {
  const dispatch = useDispatch();
  const [state, setState] = useState<IUpdateProps | undefined>(undefined);
  const [error, setError] = useState<IUpdateProps | null>(null);
  const { vendorRegisteredData, vendorDetails } = useSelector((state: any) => {
    return state.AuthReducer;
  });
  const { loading } = useSelector((state: any) => {
    return state.CommonReducer;
  });

  const { vendorId }: any = useParams();

  console.log("vendorId", vendorId);

  useEffect(() => {
    dispatch(getVendorDetails({ userId: vendorId }));
  }, []);

  useEffect(() => {
    setState(vendorDetails);
    console.log("vendordetails", vendorDetails);
  }, [vendorDetails]);

  useEffect(() => {
    console.log("vendorDetails state", state);
  }, [state]);

  // const loginUserData = JSON.parse(localStorage.getItem("userData") || "{}")
  //   ?.user?.userId;

  console.log("vendorRegisteredData", vendorRegisteredData);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let response: IValidateForm = await validateVendorUpdate(state);

    if (response.isFormValid) {
      await dispatch(
        updateVendorDetails({ userId: vendorDetails.userId, payload: state })
      );

      // setState({
      //   email: "",
      //   lastName: "",
      //   firstName: "",
      //   companyName: "",
      //   vendorApiSfcCompanyCode: "",
      // });

      setError({
        email: "",
        lastName: "",
        firstName: "",
        companyName: "",
        vendorApiSfcCompanyCode: "",
        websiteUniqueUrl: "",
        enableWebsiteUrl: false,
      });
    } else {
      setError(response.error);
    }
    // console.log("response", response, state);
  };

  const onHandleChange = (event: any) => {
    const { name, value } = event.target;
    console.log({ name, value });
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };
  const newCompanyUrl = companyUrl + vendorRegisteredData._vendorID;
  console.log({ state });
  return (
    <div className="commnbg1">
      <div className="formwidth signupform loginform">
        <h3> Edit Vendor</h3>
        {loading === false ? (
          <form>
            <div className="formItem half">
              <div className="form-group">
                <label className="input__label">
                  First Name<sup>*</sup>
                  <input
                    className="inptField"
                    name="firstName"
                    placeholder="First Name"
                    onChange={onHandleChange}
                    value={state?.firstName}
                  ></input>
                </label>
                {error?.firstName && <p>{error?.firstName}</p>}
              </div>
              {/* {error?.fname && <p>{error?.fname}</p>} */}

              <div className="form-group">
                <label className="input__label">
                  Last Name
                  <input
                    className="inptField"
                    name="lastName"
                    value={state?.lastName}
                    placeholder="Last Name"
                    onChange={onHandleChange}
                  ></input>
                </label>
              </div>
              {/* {error?.lname && <p>{error?.lname}</p>} */}
            </div>
            <div className="formItem half">
              <div className="form-group">
                <label className="input__label">
                  Email<sup>*</sup>
                  <input
                    className="inptField"
                    name="email"
                    placeholder="Email"
                    value={state?.email}
                    onChange={onHandleChange}
                  ></input>
                </label>
                {error?.email && <p>{error?.email}</p>}
              </div>
              {/* {error?.email && <p>{error?.email}</p>} */}

              <div className="form-group">
                <label className="input__label">
                  Vendor Name<sup>*</sup>
                  <input
                    name="companyName"
                    className="inptField"
                    type="text"
                    placeholder="Vendor Name"
                    onChange={onHandleChange}
                    value={state?.companyName}
                  ></input>
                </label>
                {error?.companyName && <p>{error?.companyName}</p>}
              </div>
            </div>

            <div className="formItem">
              <div className="form-group">
                <label className="input__label">
                  Vendor API SFC Company Code<sup>*</sup>
                  <input
                    name="vendorApiSfcCompanyCode"
                    className="inptField"
                    type="text"
                    placeholder="SFC Company Code"
                    value={state?.vendorApiSfcCompanyCode}
                    onChange={onHandleChange}
                  ></input>
                </label>
                {error?.vendorApiSfcCompanyCode && (
                  <p>{error?.vendorApiSfcCompanyCode}</p>
                )}
              </div>
            </div>

            <div className="formItem">
              <div className="form-group">
                <label className="input__label">
                  Website URL
                  <input
                    name="websiteUniqueUrl"
                    className="inptField"
                    type="text"
                    placeholder="Website Url"
                    value={state?.websiteUniqueUrl}
                    onChange={onHandleChange}
                  ></input>
                </label>
              </div>
              <div className="form-group">
                <label className="input__label">
                  Enable Redirect to Website URL?
                  <Switch
                    checked={
                      state &&
                      state?.enableWebsiteUrl &&
                      (state?.enableWebsiteUrl === true ||
                        state?.enableWebsiteUrl === 1)
                        ? true
                        : false
                    }
                    onChange={({ target }) =>
                      setState((prevState: any) => ({
                        ...prevState,
                        enableWebsiteUrl: target.checked,
                      }))
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  {/* <input
                    name="websiteUniqueUrl"
                    className="inptField"
                    type="text"
                    placeholder="Website Url"
                    value={state?.websiteUniqueUrl}
                    onChange={onHandleChange}
                  ></input> */}
                </label>
              </div>
            </div>
            <button className="submitBtn" onClick={handleSubmit}>
              Edit Vendor{" "}
            </button>
            {Object.keys(vendorRegisteredData).length > 0 ? (
              <p>Vendor Added Succesfully </p>
            ) : null}
          </form>
        ) : (
          <div>
            <Loader loading={loading} size={15} color={"#48a6ea"} />
          </div>
        )}
        {Object.keys(vendorRegisteredData).length > 0 ? (
          <div className="vendorData">
            <div className="vendorDetailsTitle">
              <h3>Vendor Details</h3>
            </div>
            <span>
              Vendor Name: &nbsp;{" "}
              <span>{vendorRegisteredData.companyName}</span>{" "}
            </span>
            <span>
              First Name: &nbsp;<span>{vendorRegisteredData.firstName}</span>
            </span>
            <span>
              Last Name: &nbsp;<span>{vendorRegisteredData?.lastName}</span>
            </span>
            <span>
              Email: &nbsp;<span>{vendorRegisteredData.email}</span>
            </span>
            <span>
              Vendor API Company SFC Code: &nbsp;
              <span>{vendorRegisteredData.vendorApiSfcCompanyCode}</span>
            </span>
            <span>
              Vendor Unique URL: &nbsp;
              <span>
                {newCompanyUrl}{" "}
                <FileCopy
                  onClick={() => {
                    copyToClipboard(newCompanyUrl);
                  }}
                />
              </span>
            </span>
            <span>
              Embedded Code: &nbsp;
              <span>
                {` <iframe
              width="853"
              height="480"
            src="${newCompanyUrl}"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>`}
                <FileCopy
                  onClick={() => {
                    copyToClipboard(` <iframe
                  width="853"
                  height="480"
                src="${newCompanyUrl}"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>`);
                  }}
                />
              </span>
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
