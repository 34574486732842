import React, { useEffect } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import {
  Dashboard,
  Login,
  ForgetPassword,
  ResetPassword,
  ApplicantDetails,
  VendorRegister,
  FinanceApiUser,
  ChangeVendorPassword,
} from "../controllers/index";
import { ROUTES, RoleContext } from "../services";
import { APPLICANT_ROUTES } from "../services/applicationConstants";
import { useHistory, useParams } from "react-router-dom";
import ScrollToTop from "../components/scrollToTop/scrollToTop";
import { ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import SideNav from "../components/sideNav";
import EditVendor from "../controllers/EditVendor";
import Reports from "../controllers/Reports";

function Index(props: any) {
  console.log("route props", props);
  return (
    <ScrollToTop>
      <div className="mainDiv">
        <RoleContext.Provider value={props.match.params.role}>
          <Switch>
            {!localStorage.getItem("token") ? (
              <>
                {/* <Route path={"/"} component={Login} exact /> */}

                <Route path={ROUTES.SuperAdminLogin} component={Login} exact />
                <Route
                  path={ROUTES.ForgetPassword}
                  component={ForgetPassword}
                  exact
                />

                {props?.location?.pathname != "/reset-password" && (
                  <Redirect to={ROUTES.SuperAdminLogin} from="/" />
                )}
                <Route
                  path={ROUTES.ResetPassword}
                  component={ResetPassword}
                  exact
                />
              </>
            ) : (
              <>
                {/* <Route path={"/"} component={Dashboard} exact /> */}
                <SideNav />
                <div className="right-page">
                  <Route path={"/"} component={Dashboard} exact />
                  <Route path={ROUTES.Dashboard} component={Dashboard} exact />
                  <Route
                    path={ROUTES.VendorRegister}
                    component={VendorRegister}
                    exact
                  />
                  <Route
                    path={`${ROUTES.EditVendor}/:vendorId`}
                    component={EditVendor}
                    exact
                  />
                  <Route path={`${ROUTES.Reports}`} component={Reports} exact />
                  <Route
                    path={ROUTES.FinanceApiUser}
                    component={FinanceApiUser}
                    exact
                  />

                  <Route
                    path={ROUTES.ApplicantDetails}
                    component={ApplicantDetails}
                    exact
                  />
                  <Route
                    path={ROUTES.ChangeVendorPassword}
                    component={ChangeVendorPassword}
                    exact
                  />

                  <Redirect to={ROUTES.Dashboard} from="/" />
                </div>
              </>
            )}
          </Switch>
        </RoleContext.Provider>
      </div>
    </ScrollToTop>
  );
}
export default withRouter(Index);
