export const renderPieData = (vendorReport: any, mapOptions: any) => {
  if (vendorReport.length === 0) return [1, 1];
  else return [mapOptions?.success, mapOptions?.fail];
};

export const renderBgColor = (vendorReport: any) => {
  if (vendorReport.length === 0)
    return ["rgba(173, 173, 173, 1)", "rgba(173, 173, 173, 1)"];
  else return ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"];
};

export const renderBorderColor = (vendorReport: any) => {
  if (vendorReport.length === 0)
    return ["rgba(173, 173, 173, 1)", "rgba(173, 173, 173, 1)"];
  else return ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"];
};
