import { useState, useEffect } from "react";

import "./Login.css";
import { validateLogIn } from "./Validate";
import {
  forgetPasswordAction,
  loginAction,
  resetPasswordAction,
} from "../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import QueryString from "query-string";

interface LoginIProps {
  newPassword: string | undefined;
  confirmPassword: string | undefined;
  resetKey?: string | undefined;
}

interface ValidateForm {
  isFormValid: boolean;
  error: LoginIProps;
}

const Index: React.FC = (props: any) => {
  const dispatch = useDispatch();

  const [state, setState] = useState();
  const [error, setError] = useState<LoginIProps | null>(null);

  useEffect(() => {
    const accessKey = QueryString.parse(props.location.search);
    console.log("accessKey", accessKey);
    setState((prevState: any) => ({
      ...prevState,
      resetKey: accessKey["token"],
    }));
  }, []);

  const loading = useSelector((state: any) => {
    return state.CommonReducer.loading;
  });
  const onHandleChange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let response: ValidateForm = await validateLogIn(state);
    console.log("response", state);
    if (response.isFormValid) {
      setError(null);
      await dispatch(resetPasswordAction(state, props));
      props.history.push("/login");
    } else {
      setError(response.error);
    }
  };
  return (
    <div className="commnbg">
      <div className="formwidth loginform">
        <h1>ELEVATE </h1>
        <form>
          <h3>Reset Password</h3>
          <div className="formItem">
            <div className="form-group">
              <label className="input__label">
                New Password
                <input
                  className="inptField"
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                  onChange={onHandleChange}
                ></input>
              </label>
              {error?.newPassword && <p>{error?.newPassword}</p>}
            </div>

            <div className="form-group">
              <label className="input__label">
                Confirm Password
                <input
                  className="inptField"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  type="password"
                  onChange={onHandleChange}
                ></input>
              </label>
              {error?.confirmPassword && <p>{error?.confirmPassword}</p>}
            </div>
          </div>
          <div className="checkdiv">
            <Link to="/login">Login?</Link>
          </div>
          <button className="submitBtn" onClick={handleSubmit}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Index;
