export const LOADING_FAILURE = "LOADING_FAILURE";
export const LOADING_STARTED = "LOADING_STARTED";
export const LOADING_COMPLETED = "LOADING_COMPLETED";

export const IS_USER_LOGIN = "IS_USER_LOGIN";
export const LOGIN_DATA = "LOGIN_DATA";

export const SIGN_UP = "SIGN_UP";

export const APPLICANT_DATA = "APPLICANT_DATA";

export const USER_DETAILS = "USER_DETAILS";
export const SIGNUP = "SIGNUP";
export const GET_ALL_VENDORS = "GET_ALL_VENDORS";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";
export const CLEAR_ALL_VENDORS = "CLEAR_ALL_VENDORS";
export const CLEAR_VENDOR_REGISTERED_DATA = "CLEAR_VENDOR_REGISTERED_DATA";

// Reports
export const GET_VENDOR_LIST = "GET_VENDOR_LIST";
export const GET_VENDOR_REPORT = "GET_VENDOR_REPORT";
export const CLEAR_REPORT = "CLEAR_REPORT";
