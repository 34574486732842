import Routing from "./routing/index";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SideNav from "./components/sideNav";

function App(props: any) {
  return (
    <div className="App">
      <Header />
      <div className="outerDiv">
        <Routing />
      </div>
      <Footer />
    </div>
  );
}

export default App;
