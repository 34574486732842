import { ROUTES } from "../../services";
import axios from "../../services/axiosService";
import { toast } from "../../utils/toast";
import {
  LOGIN_DATA,
  LOADING_COMPLETED,
  LOADING_FAILURE,
  LOADING_STARTED,
  IS_USER_LOGIN,
  APPLICANT_DATA,
  USER_DETAILS,
  SIGNUP,
  GET_ALL_VENDORS,
  UPDATE_VENDOR,
  GET_VENDOR_DETAILS,
  GET_VENDOR_LIST,
  GET_VENDOR_REPORT,
  CLEAR_REPORT,
  CLEAR_VENDOR_REGISTERED_DATA,
} from "../types";

export const loginAction =
  (body: undefined, props: any) => async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });
      const res = await axios.post("/admin/login", body);
      let { data } = res.data;
      console.log("SATA", data);
      if (
        res?.data?.status &&
        res?.data?.code === 200 &&
        data?.user?.role === 1
      ) {
        localStorage.setItem("userData", JSON.stringify(data));
        localStorage.setItem("token", data.token);
        await dispatch({ type: LOGIN_DATA, payload: data });
        await dispatch({ type: IS_USER_LOGIN, payload: true });
        await dispatch({ type: LOADING_COMPLETED });
        await toast.success("Login Successful");
        props.history.push(ROUTES.Dashboard);
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Login Error!!");
    }
  };

export const signUpAction =
  (body: any, props: any, loginUserId: any) => async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });
      body.loginUserId = loginUserId;
      const res = await axios.post("/super-admin/signup", body);
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        await dispatch({ type: SIGNUP, payload: data });
        await dispatch({ type: LOADING_COMPLETED });
        await toast.success("Vendor Created Successfully");
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Login Error!!");
    }
  };

// Reports

export const getVendorList =
  ({ loginUserId }: any) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });
      dispatch({ type: CLEAR_REPORT });

      const res = await axios.post(`/super-admin/report/vendors/list`, {
        loginUserId,
      });
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        console.log("getVendorList action", data);
        await dispatch({ type: GET_VENDOR_LIST, payload: data });
        await dispatch({ type: LOADING_COMPLETED });
        // await toast.success('Vendor Created Successfully');
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Login Error!!");
    }
  };

export const generateReport =
  ({ vendorId, reportType, dateFilter }: any) =>
  async (dispatch: any) => {
    try {
      // dispatch({ type: LOADING_STARTED });

      const res = await axios.post(`/super-admin/report/generate`, {
        vendorId,
        reportType,
        dateFilter,
      });
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        console.log("generateReport action", data);
        await dispatch({ type: GET_VENDOR_REPORT, payload: data });
        // await dispatch({ type: LOADING_COMPLETED });
        // await toast.success('Vendor Created Successfully');
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Error generating report!!");
    }
  };

export const exportReport =
  ({ vendorId, reportType, dateFilter }: any) =>
  async (dispatch: any) => {
    try {
      // dispatch({ type: LOADING_STARTED });

      const res = await axios.post(
        `/super-admin/report/export`,
        {
          vendorId,
          reportType,
          dateFilter,
        },
        {
          responseType: "blob",
        }
      );
      console.log("exportReport outside", res.data, res);

      if (res?.data && res?.status === 200) {
        // let { data } = res.data;
        console.log("exportReport action", res.data);

        // let formattedFileName = formatDateForCsv(params);

        console.log("res.data", res.data);
        const blob = await res.data;
        const file = new Blob([blob], {
          type: "text/csv",
        });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
        // await dispatch({ type: GET_VENDOR_REPORT, payload: data });
        // await dispatch({ type: LOADING_COMPLETED });
        // await toast.success('Vendor Created Successfully');
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Export Error!!");
    }
  };

export const sendReport =
  ({ loginUserId, vendorId, reportType, dateFilter }: any) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });

      const res = await axios.post(`/super-admin/report/sendMail`, {
        loginUserId,
        vendorId,
        reportType,
        dateFilter,
      });
      console.log("exportReport outside", res.data, res);

      if (res?.data && res?.status === 200) {
        // let { data } = res.data;
        console.log("exportReport action", res.data);

        // let formattedFileName = formatDateForCsv(params);

        console.log("res.data", res.data);
        // const blob = await res.data;
        // const file = new Blob([blob], {
        //   type: "text/csv",
        // });
        // //Build a URL from the file
        // const fileURL = URL.createObjectURL(file);

        // const link = document.createElement("a");
        // link.href = fileURL;
        // link.setAttribute("download", "export.csv");
        // document.body.appendChild(link);
        // link.click();
        // await dispatch({ type: GET_VENDOR_REPORT, payload: data });

        await dispatch({ type: LOADING_COMPLETED });
        toast.success("Mail Sent");
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Error sending report!!");
    }
  };

// ////////////////

export const getAllVendors =
  (skip: any, loginUserId: any) => async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });

      const res = await axios.post(`/super-admin/vendors/all/${skip}`, {
        loginUserId,
      });
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        console.log("getAllVendors action", data);
        await dispatch({ type: GET_ALL_VENDORS, payload: data });
        await dispatch({ type: LOADING_COMPLETED });
        // await toast.success('Vendor Created Successfully');
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Login Error!!");
    }
  };

export const changeVendorStatus =
  ({ userId, isActive }: any) =>
  async (dispatch: any) => {
    try {
      console.log("changeVendorStatus", userId);
      dispatch({ type: LOADING_STARTED });

      const res = await axios.post(`/super-admin/vendor/changeStatus`, {
        userId,
        isActive,
      });
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        console.log("getAllVendors action", data);
        await dispatch({ type: UPDATE_VENDOR, payload: data });
        await dispatch({ type: LOADING_COMPLETED });
        await toast.success("Finance API User Status Updated!");
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Login Error!!");
    }
  };

export const updateVendorApiUser =
  (payload: any, userId: any) => async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });
      payload.userId = userId;
      const res = await axios.post(
        `/super-admin/changeVendorApiUsers`,
        payload
      );
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        console.log("getAllVendors action", data);
        await dispatch({ type: GET_ALL_VENDORS, payload: data });
        await dispatch({ type: LOADING_COMPLETED });
        await toast.success("Finance API User updated!");
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      toast.error("Login Error!!");
    }
  };

export const getVendorDetails =
  ({ userId }: any) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });
      dispatch({ type: CLEAR_VENDOR_REGISTERED_DATA });

      const res = await axios.get(`/super-admin/vendor/details/${userId}`);
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        console.log("data in vendorDetails", data);
        dispatch({ type: LOADING_COMPLETED });

        dispatch({ type: GET_VENDOR_DETAILS, payload: data });
        return data;
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
    }
  };

export const updateVendorDetails =
  ({ userId, payload }: any) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });

      const res = await axios.post(
        `/super-admin/vendor/details/edit/${userId}`,
        payload
      );
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        console.log("data in updateVendorDetails", data);
        toast.success("Details updated");
        dispatch({ type: LOADING_COMPLETED });

        // dispatch({ type: GET_VENDOR_DETAILS, payload: data });
        return data;
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
    }
  };

export const forgetPasswordAction =
  (state: undefined, type: any) => async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });
      const res = await axios.post("/admin/forgotPassword", { state, type });
      if (res?.data?.status && res?.data?.code === 200) {
        // let { data } = res.data;
        // await toast.success("Login Successful");
        await toast.success(res?.data?.data);
      } else {
        // toast.error("Something Went Worng!");
        await toast.error(res?.data?.data);
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      await toast.error("Something went wrong!");
      // toast.error("Login Error!!");
    }
  };
export const resetPasswordAction =
  (body: undefined, props: any) => async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });
      const res = await axios.post("/admin/resetPassword", body);
      if (res?.data?.status && res?.data?.code === 200) {
        await toast.success(res?.data?.data);
      } else {
        await toast.success(res?.data?.data);
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      await toast.success("Something went wrong!");
    }
  };

export const getAdminUserDetailsAction =
  (skip: any) => async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });
      const res = await axios.get("/admin/getAllApplications/" + skip);
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        await dispatch({ type: APPLICANT_DATA, payload: data });
        await dispatch({ type: LOADING_COMPLETED });
      } else {
        // toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
      // toast.error("Login Error!!");
    }
  };

export const getAdminSuccessAction = (skip: any) => async (dispatch: any) => {
  try {
    dispatch({ type: LOADING_STARTED });
    const res = await axios.get("/admin/getAllSuccessApplications/" + skip);
    if (res?.data?.status && res?.data?.code === 200) {
      let { data } = res.data;
      await dispatch({ type: APPLICANT_DATA, payload: data });
      await dispatch({ type: LOADING_COMPLETED });
    } else {
      // toast.error("Something Went Worng!");
    }
  } catch (error) {
    dispatch({ type: LOADING_FAILURE });
    // toast.error("Login Error!!");
  }
};
export const getAdminFailureAction = (skip: any) => async (dispatch: any) => {
  try {
    dispatch({ type: LOADING_STARTED });
    const res = await axios.get("/admin/getAllFailedApplications/" + skip);
    if (res?.data?.status && res?.data?.code === 200) {
      let { data } = res.data;
      await dispatch({ type: APPLICANT_DATA, payload: data });
      await dispatch({ type: LOADING_COMPLETED });
    } else {
      // toast.error("Something Went Worng!");
    }
  } catch (error) {
    dispatch({ type: LOADING_FAILURE });
    // toast.error("Login Error!!");
  }
};

export const getUserDetails = (_id: any) => async (dispatch: any) => {
  try {
    dispatch({ type: LOADING_STARTED });
    const res = await axios.get("/admin/getApplicantDetails/" + _id);
    if (res?.data?.status && res?.data?.code === 200) {
      let { data } = res.data;
      await dispatch({ type: USER_DETAILS, payload: data });
      await dispatch({ type: LOADING_COMPLETED });
    } else {
      // toast.error("Something Went Worng!");
    }
  } catch (error) {
    dispatch({ type: LOADING_FAILURE });
    // toast.error("Login Error!!");
  }
};

export const MatchTokenAction = async (dispatch: any) => {
  try {
    const res = await axios.get("/admin/matchtoken");
    if (res?.data?.status && res?.data?.code === 200) {
      let { data } = res.data;
      await dispatch({ type: LOGIN_DATA, payload: res.data });
      localStorage.setItem("userData", JSON.stringify(data));
      localStorage.setItem("token", data.token);
    } else {
      //   toast.error("Something Went Worng!");
    }
  } catch (error) {
    dispatch({ type: LOADING_FAILURE });
    // toast.error("Login Error!!");
  }
};

export const LogOutAction = (props: any) => async (dispatch: any) => {
  localStorage.clear();
  await dispatch({ type: IS_USER_LOGIN, payload: false });
  props.history.push(ROUTES.Login);
};

export const getVendorApiUser =
  (payload: any, userId: any) => async (dispatch: any) => {
    try {
      dispatch({ type: LOADING_STARTED });

      const res = await axios.get(`/super-admin/getVendorApiUsers/${userId}`);
      if (res?.data?.status && res?.data?.code === 200) {
        let { data } = res.data;
        return data;
      } else {
        toast.error("Something Went Worng!");
      }
    } catch (error) {
      dispatch({ type: LOADING_FAILURE });
    }
  };
