import { useEffect, useState } from "react";

import "./style.css";

import {
  changeVendorStatus,
  exportReport,
  generateReport,
  getVendorList,
  sendReport,
} from "../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// import { Button } from "@material-ui/core";
import Button from "../../components/Button/button";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker, PickersDay, StaticDatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import Table from "../../components/TableNew/Table";

import { styled } from "@mui/material/styles";
import moment from "moment";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import Dropdown from "../../components/DropDown/DropDown";
import { Divider, Grid, Paper } from "@material-ui/core";
import { renderBgColor, renderBorderColor, renderPieData } from "./helper";

ChartJS.register(ArcElement, Tooltip, Legend);

enum EReportTypes {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  CUSTOM = "custom",
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop: any) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }: any) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

export default function Reports(props: any) {
  const dispatch = useDispatch();

  const loginUserId = JSON.parse(localStorage.getItem("userData") || "{}").user
    ?.userId;

  const { vendorRegisteredData, vendorDetails, vendorList, vendorReport } =
    useSelector((state: any) => {
      return state.AuthReducer;
    });

  const { loading } = useSelector((state: any) => {
    return state.CommonReducer;
  });

  const [reportType, setReportType] = useState<EReportTypes>(
    EReportTypes.DAILY
  );
  const [value, setValue] = useState<any>(new Date());
  const [customValue, setCustomValue] = useState<any>({
    startDate: moment().format("MM/DD/YYYY"),
    endDate: moment().format("MM/DD/YYYY"),
  });
  const [vendorId, setVendorId] = useState<any>();
  const [dateFilter, setDateFilter] = useState<any>({
    startDate: moment().format("MM/DD/YYYY"),
    endDate: moment().format("MM/DD/YYYY"),
    date: moment().format("MM/DD/YYYY"),
    month: moment().format("MM"),
  });

  const [mapOptions, setMapOptions] = useState<any>({
    success: 0,
    fail: 0,
  });

  useEffect(() => {
    dispatch(getVendorList({ loginUserId }));
  }, []);

  // useEffect(() => {}, [vendorList]);

  console.log("vendorRegisteredData", vendorRegisteredData);

  useEffect(() => {
    setApplicationCount();
  }, [vendorReport]);

  const setApplicationCount = () => {
    let success = 0,
      fail = 0;

    console.log("vendorReport", vendorReport);
    if (vendorReport.length > 0)
      vendorReport.map((curr: any) => {
        console.log("curr", curr);
        if (curr?.specialFinanceApiStatus === 0) fail++;
        if (curr?.specialFinanceApiStatus === 1) success++;
      });
    setMapOptions({
      ...mapOptions,
      success,
      fail,
    });
    console.log("success, fail", success, fail);
  };

  const handleFreqChange = (type: EReportTypes) => {
    console.log("type", type);
    setReportType(type);
  };

  const renderWeekPickerDay = (
    date: any,
    selectedDates: any,
    pickersDayProps: any
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    // const start = moment.startOfWeek(value);
    const start = moment(value).startOf("week");
    const end = moment(value).endOf("week");
    // const end = moment.endOfWeek(value);
    console.log("value,date,  start, end", value, date, start, end);

    // const dayIsBetween = moment.isWithinRange(date, { start, end });
    const dayIsBetween = moment(date).isBetween(start, end, undefined, "[]");
    const isFirstDay = moment(date).isSame(start, "day");
    const isLastDay = moment(date).isSame(end, "day");
    // const isFirstDay = moment.isSameDay(date, start);
    // const isLastDay = moment.isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const handleDateChange = ({ newValue, reportType }: any) => {
    setDateFilter({
      ...dateFilter,
      date: moment(newValue).format("MM/DD/YYYY"),
      reportType,
    });
  };

  const handleDateStartEndChange = ({
    startDate,
    endDate,
    reportType,
  }: any) => {
    setDateFilter({
      ...dateFilter,
      startDate: moment(startDate).format("MM/DD/YYYY"),
      endDate: moment(endDate).format("MM/DD/YYYY"),
      reportType,
    });
  };

  const renderMapOptions = () => {
    switch (reportType) {
      case EReportTypes.DAILY:
        return (
          <div className="DatePicker">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                openTo="year"
                views={["year", "month", "day"]}
                label="Date"
                value={value}
                onChange={(newValue) => {
                  console.log(
                    "modified date"
                    // moment(newValue).format("DD/MM/YYYY")
                  );
                  handleDateChange({ newValue, reportType });

                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </div>
        );

      case EReportTypes.WEEKLY:
        return (
          <div className="DatePicker">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                // displayStaticWrapperAs="desktop"
                label="Choose Week"
                value={value}
                onChange={(newValue) => {
                  console.log(
                    "modified week"
                    // moment(newValue).startOf("week").format("DD/MM/YYYY"),
                    // moment(newValue).format("DD/MM/YYYY"),
                    // moment(newValue).endOf("week").format("DD/MM/YYYY")
                  );

                  // handleDateStartEndChange({ startDate, endDate, reportType });
                  handleDateChange({ newValue, reportType });

                  setValue(newValue);
                }}
                renderDay={renderWeekPickerDay}
                renderInput={(params) => <TextField {...params} />}
                inputFormat="W in MMMM YYYY"
              />
            </LocalizationProvider>
          </div>
        );

      case EReportTypes.MONTHLY:
        return (
          <div className="DatePicker">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                openTo="year"
                views={["year", "month"]}
                label="Choose month"
                value={value}
                onChange={(newValue) => {
                  console.log(
                    "modified month"

                    // moment(newValue).format("DD/MM/YYYY")
                  );

                  handleDateChange({ newValue, reportType });

                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </div>
        );
      case EReportTypes.CUSTOM:
        return (
          <>
            <LocalizationProvider dateAdapter={DateAdapter}>
              <DatePicker
                openTo="year"
                views={["year", "month", "day"]}
                label="Start Date"
                value={customValue.startDate}
                onChange={(newValue) => {
                  console.log(
                    "modified custom start date"

                    // moment(newValue).format("DD/MM/YYYY")
                  );

                  setDateFilter({
                    ...dateFilter,
                    startDate: moment(newValue).format("MM/DD/YYYY"),
                    reportType,
                  });
                  setCustomValue({
                    ...customValue,
                    startDate: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
              <DatePicker
                openTo="year"
                views={["year", "month", "day"]}
                label="End Date"
                value={customValue.endDate}
                onChange={(newValue) => {
                  console.log(
                    "modified custom end date"

                    // moment(newValue).format("DD/MM/YYYY")
                  );
                  setDateFilter({
                    ...dateFilter,
                    endDate: moment(newValue).format("MM/DD/YYYY"),
                    reportType,
                  });
                  setCustomValue({
                    ...customValue,
                    endDate: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </>
        );

      default:
        break;
    }
  };

  const renderVendorInformation = () => {
    const list = vendorList.filter(
      (vendor: any) => vendor["_vendorID"] === vendorId
    );

    console.log("selectedVendor", list);
    // setSelectedVendor(selectedVendor);
    if (list.length > 0) {
      let selectedVendor = list[0];
      return (
        <Grid container>
          <Grid container className="rowLine mt-4">
            <Grid item xs={4} className="labels">
              <h5> First Name </h5>
            </Grid>
            <Grid item xs={8} className="values">
              {selectedVendor["firstName"]}
            </Grid>
          </Grid>

          <Grid container className="rowLine">
            <Grid item xs={4} className="labels">
              <h5> Last Name</h5>
            </Grid>
            <Grid item xs={8} className="values">
              {selectedVendor["lastName"]}
            </Grid>
          </Grid>

          <Grid container className="rowLine">
            <Grid item xs={4} className="labels">
              <h5> Email</h5>
            </Grid>
            <Grid item xs={8} className="values">
              {selectedVendor["email"]}
            </Grid>
          </Grid>

          <Grid container className="rowLine">
            <Grid item xs={4} className="labels">
              <h5> Company Name</h5>
            </Grid>
            <Grid item xs={8} className="values">
              {selectedVendor["companyName"]}
            </Grid>
          </Grid>

          <Grid container className="rowLine">
            <Grid item xs={4} className="labels">
              <h5> SFC Code</h5>
            </Grid>
            <Grid item xs={8} className="values">
              {selectedVendor["vendorApiSfcCompanyCode"]}
            </Grid>
          </Grid>

          <Grid container className="rowLine">
            <Grid item xs={4} className="labels">
              <h5> Vendor ID</h5>
            </Grid>
            <Grid item xs={8} className="values">
              {selectedVendor["_vendorID"]}
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return <div className="noVendor">Please select a vendor</div>;
    }
  };

  const dataPie = {
    labels: ["Success", "Fail"],
    datasets: [
      {
        label: "# of Applications",
        data: renderPieData(vendorReport, mapOptions),
        backgroundColor: renderBgColor(vendorReport),
        borderColor: renderBorderColor(vendorReport),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="report wrapper">
      <div className="">
        <h3> Reports</h3>
      </div>

      <div className="d-flex flex-column">
        {" "}
        <div className="d-flex justify-content-around align-items-center flex-wrap item items2">
          <Dropdown
            name="ss"
            label="Select Vendor"
            handleChange={(e: any) => {
              console.log("choose vendor handle change", e.target.value);
              setVendorId(e.target.value);
              // handleFreqChange(e.target.value);
            }}
            value={null}
            itemId={"itemId"}
            itemLabel={"itemLabel"}
            menuItems={
              vendorList.length > 0 &&
              vendorList.map((vendor: any) => {
                return {
                  itemId: vendor["_vendorID"],
                  itemLabel: vendor["firstName"] + " " + vendor["lastName"],
                };
              })
            }
          />

          <Dropdown
            name="ss"
            label="Select Duration"
            handleChange={(e: any) => {
              handleFreqChange(e.target.value);
            }}
            value={null}
            itemId={"itemId"}
            itemLabel={"itemLabel"}
            menuItems={[
              {
                itemId: EReportTypes.DAILY,
                itemLabel: "Daily",
              },
              {
                itemId: EReportTypes.WEEKLY,
                itemLabel: "Weekly",
              },
              {
                itemId: EReportTypes.MONTHLY,
                itemLabel: "Monthly",
              },
              {
                itemId: EReportTypes.CUSTOM,
                itemLabel: "Custom",
              },
            ]}
          />

          {renderMapOptions()}

          <Button
            color={"secondary"}
            background={"primary"}
            name="Generate Report"
            handleClick={() => {
              let dateFilterToSend: {
                [k: string]: any;
              } = {};
              ["date", "startDate", "endDate"].map((dateType: any) => {
                console.log(
                  "dateFilter[dateType]",
                  dateFilter[dateType],
                  dateType
                );
                if (dateFilter[dateType]) {
                  console.log(
                    "dateFilter[dateType] in cond",
                    dateFilter[dateType],
                    dateType
                  );

                  dateFilterToSend[dateType] = moment(
                    dateFilter[dateType],
                    "MM/DD/YYYY"
                  ).format("YYYY-MM-DD");
                }
              });

              if (reportType === EReportTypes.MONTHLY)
                dateFilterToSend["month"] = moment(
                  dateFilterToSend["date"]
                ).format("MM");

              if (reportType === EReportTypes.WEEKLY) {
                let startDate = moment(dateFilterToSend["date"])
                  .startOf("week")
                  .format("YYYY-MM-DD");
                let endDate = moment(dateFilterToSend["date"])
                  .endOf("week")
                  .format("YYYY-MM-DD");

                console.log("weekly", startDate, endDate);
                dateFilterToSend["startDate"] = startDate;
                dateFilterToSend["endDate"] = endDate;
              }

              console.log("dateFilterToSend", dateFilterToSend);

              dispatch(
                generateReport({
                  vendorId,
                  reportType,
                  dateFilter: dateFilterToSend,
                })
              );
            }}
          />
        </div>
        {/* <div className="item">{renderMapOptions()}</div> */}
        <div className="item d-flex justify-content-around align-items-start">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md>
              <Paper elevation={2} className="leftBox">
                <h5 className="leftBoxTitle p-2">Vendor Information</h5>
                <Divider />

                {renderVendorInformation()}
              </Paper>
            </Grid>
            {vendorReport?.length > 0 ? (
              <Grid item xs={12} sm={12} md>
                <Paper elevation={2} className="rightBox">
                  <div className=" charts align-self-center  ">
                    {" "}
                    <Pie data={dataPie} className="p-3" />
                    <div className="mb-2">
                      Total Applications : {vendorReport?.length}
                    </div>
                    <div className="mb-2">
                      <span className="dataPass">
                        Success : {mapOptions?.success}
                      </span>
                      <span className="dataFail">
                        Fail : {mapOptions?.fail}
                      </span>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </div>
        <Divider />
        <div className="item tableTitle d-flex justify-content-between ">
          <div className="Tableheading">
            <h4>Loan Applications</h4>
          </div>
          <div className="d-flex">
            <Button
              color={"secondary"}
              background={"primary"}
              name="Export Report"
              handleClick={() => {
                let dateFilterToSend: {
                  [k: string]: any;
                } = {};
                ["date", "startDate", "endDate"].map((dateType: any) => {
                  console.log(
                    "dateFilter[dateType]",
                    dateFilter[dateType],
                    dateType
                  );
                  if (dateFilter[dateType]) {
                    console.log(
                      "dateFilter[dateType] in cond",
                      dateFilter[dateType],
                      dateType
                    );

                    dateFilterToSend[dateType] = moment(
                      dateFilter[dateType],
                      "MM/DD/YYYY"
                    ).format("YYYY-MM-DD");
                  }
                });

                if (reportType === EReportTypes.MONTHLY)
                  dateFilterToSend["month"] = moment(
                    dateFilterToSend["date"]
                  ).format("MM");

                if (reportType === EReportTypes.WEEKLY) {
                  let startDate = moment(dateFilterToSend["date"])
                    .startOf("week")
                    .format("YYYY-MM-DD");
                  let endDate = moment(dateFilterToSend["date"])
                    .endOf("week")
                    .format("YYYY-MM-DD");

                  console.log("weekly", startDate, endDate);
                  dateFilterToSend["startDate"] = startDate;
                  dateFilterToSend["endDate"] = endDate;
                }

                console.log("dateFilterToSend", dateFilterToSend);

                dispatch(
                  exportReport({
                    vendorId,
                    reportType,
                    dateFilter: dateFilterToSend,
                  })
                );
              }}
            />
            <Button
              color={"secondary"}
              background={"primary"}
              name="Send via Email"
              loading={loading}
              handleClick={() => {
                let dateFilterToSend: {
                  [k: string]: any;
                } = {};
                ["date", "startDate", "endDate"].map((dateType: any) => {
                  console.log(
                    "dateFilter[dateType]",
                    dateFilter[dateType],
                    dateType
                  );
                  if (dateFilter[dateType]) {
                    console.log(
                      "dateFilter[dateType] in cond",
                      dateFilter[dateType],
                      dateType
                    );

                    dateFilterToSend[dateType] = moment(
                      dateFilter[dateType],
                      "MM/DD/YYYY"
                    ).format("YYYY-MM-DD");
                  }
                });

                if (reportType === EReportTypes.MONTHLY)
                  dateFilterToSend["month"] = moment(
                    dateFilterToSend["date"]
                  ).format("MM");

                if (reportType === EReportTypes.WEEKLY) {
                  let startDate = moment(dateFilterToSend["date"])
                    .startOf("week")
                    .format("YYYY-MM-DD");
                  let endDate = moment(dateFilterToSend["date"])
                    .endOf("week")
                    .format("YYYY-MM-DD");

                  console.log("weekly", startDate, endDate);
                  dateFilterToSend["startDate"] = startDate;
                  dateFilterToSend["endDate"] = endDate;
                }

                console.log("dateFilterToSend", dateFilterToSend);

                dispatch(
                  sendReport({
                    loginUserId,
                    vendorId,
                    reportType,
                    dateFilter: dateFilterToSend,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="item tableItem">
          <Table
            tableHead={["Date", "Applicant Name", "Email", "Submission Status"]}
            // action={true}
            tableData={
              vendorReport.length > 0 &&
              vendorReport.map((elm: any) => ({
                _id: elm?._id,
                Date: moment(elm?.createdAt.split("T")[0]).format("MM/DD/YYYY"),
                "Applicant Name": elm?.firstName,
                Email: elm?.email,
                "Submission Status":
                  elm?.specialFinanceApiStatus === 1 ? "Success" : "Fail",
              }))
            }
            // button="yes"
            // buttonTwo="yes"
            // handleBtnClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
}
