import {
  APPLICANT_DATA,
  IS_USER_LOGIN,
  LOGIN_DATA,
  USER_DETAILS,
  SIGNUP,
  GET_ALL_VENDORS,
  UPDATE_VENDOR,
  GET_VENDOR_DETAILS,
  GET_VENDOR_LIST,
  GET_VENDOR_REPORT,
  CLEAR_REPORT,
  CLEAR_VENDOR_REGISTERED_DATA,
} from "../types";

export const initialState: any = {
  user: "",
  isUserLogin: false,
  applicantData: [],
  userDetails: [],
  vendorRegisteredData: {},
  allVendors: {},
  vendorDetails: {},
  vendorList: [],
  vendorReport: [],
};

export const AuthReducer = (state = initialState, action: any) => {
  console.log("action payload", action);
  switch (action.type) {
    case LOGIN_DATA:
      let { data } = action.payload;
      // console.log("setting userdata", data);
      return {
        ...state,
        user: data,
      };
    case IS_USER_LOGIN:
      return {
        ...state,
        isUserLogin: action.payload,
      };
    case "LOGOUT_USER":
      localStorage.clear();
      return {
        isUserLogin: false,
        user: "",
      };

    case APPLICANT_DATA:
      return {
        ...state,
        applicantData: action.payload,
      };
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SIGNUP:
      return {
        ...state,
        vendorRegisteredData: action.payload,
      };

    case CLEAR_VENDOR_REGISTERED_DATA:
      return {
        ...state,
        vendorRegisteredData: {},
        vendorDetails: {},
      };

    case GET_ALL_VENDORS:
      return {
        ...state,
        allVendors: action.payload,
      };
    case GET_ALL_VENDORS:
      return {
        ...state,
        allVendors: {},
      };

    case GET_VENDOR_DETAILS:
      return {
        ...state,
        vendorDetails: action.payload.data,
      };

    case UPDATE_VENDOR:
      console.log("action.payload", action.payload);

      const { userId, isActive } = action.payload.data;
      const index = state.allVendors?.data.findIndex(
        (vendor: any) => vendor.userId === userId
      );

      console.log("index", index);

      return {
        ...state,
        allVendors: {
          ...state.allVendors,
          data: [
            ...state.allVendors?.data.slice(0, index), // everything before current post
            {
              ...state.allVendors?.data[index],
              isActive,
            },
            ...state.allVendors?.data.slice(index + 1), // everything after current post
          ],
        },
      };
    // break;

    // Reports

    case GET_VENDOR_LIST:
      console.log("in vendor list reducer");
      return {
        ...state,
        vendorList: action.payload,
      };

    case GET_VENDOR_REPORT:
      console.log("in vendor report reducer");
      return {
        ...state,
        vendorReport: action.payload,
      };

    case CLEAR_REPORT:
      console.log("in clear report reducer");
      return {
        ...state,
        vendorReport: [],
      };

    default:
      return state;
  }
};
