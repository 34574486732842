import React from "react";
import { Button } from "@material-ui/core";
import "./button.css";
import Loader from "../Loader";

const CustomButton = (props) => {
  return (
    <div style={{ textAlign: props.textAlign }}>
      <Button
        className={
          props.background === "primary" ? "signOutbtnP" : "signOutbtnS"
        }
        style={{
          cursor: "pointer",

          color:
            props.color === "primary"
              ? "#48A6EA"
              : props.color === "secondary"
              ? "#ffffff"
              : props.color === "tertiary"
              ? "#000000"
              : props.color,
          background: props.background === "primary" ? "#48A6EA " : "#ffffff",
          borderRadius: "3px",
          fontSize: "14px",
          textTransform: "capitalize",
          padding: "5px 25px",
          border: props.border || "1px solid",
          transition: "all 0.5s ease",
          display: "inline-flex",
          alignItem: "center",
          marginRight: props.marginRight,
        }}
        disabled={props?.disabled}
        onClick={props.handleClick}
      >
        <div className="iconButton">{props.icon ? props.icon : ""}</div>

        {props.name}
        <Loader loading={props.loading}></Loader>
      </Button>
    </div>
  );
};
export default CustomButton;
