import Button from "../Button/button";
import "./Table.css";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  tableStart: {
    boxShadow: "none",
  },
  table: {
    minWidth: 650,
    textAlign: "center",

    "& thead.MuiTableHead-root": {
      position: "sticky",
      top: 0,
      background: "#ffffff",
      zIndex: 1,
      borderBottom: "1px solid grey",
    },
  },
  icon: {
    color: "#ED4B34",
    cursor: "pointer",
    "&   .MuiSvgIcon-root:hover": {
      color: "grey",
    },
  },
  buttonTwo: {
    border: "1px solid #ED4B34",
    backgroundColor: "transparent",
    color: "#414257",
  },
});

interface ITableProps {
  tableHead: string[];
  tableData: object[];
}

// const Tabletab = (props: ITableProps) => {
const Tabletab = ({ tableHead, tableData }: ITableProps) => {
  const classes = useStyles();

  // const { tableHead, tableData } = props; //tableHead = array of columns, tableData = array of array of row data
  if (tableHead == null || tableData == null) {
    return null;
  }

  console.log("table", tableData);

  return (
    <div className="tableCover">
      <TableContainer
        // style={{ height: props.height }}
        className={classes.tableStart}
        component={Paper}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHead?.map((key) => (
                <TableCell>{key}</TableCell>
              ))}
              {/* {props.button ? <TableCell></TableCell> : null}{" "} */}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.length > 0 &&
              tableData?.map((item: any, index) => (
                <TableRow
                  key={item?.id}
                  style={
                    index % 2
                      ? { background: "white" }
                      : { background: "#F2FBFE" }
                  }
                >
                  {tableHead.map((val, index) => {
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        // onClick={(e) => props.handleDropdownToggle(e, val, item)}
                      >
                        {item[val]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div className="text-right">
        <ArrowLeftIcon
          className="pageIcon"
          onClick={(e) => getCurrentData(tableData, "less", e)}
          style={{ color: "black" }}
        />
        <ArrowRightIcon
          className="pageIcon"
          onClick={(e) => getCurrentData(tableData, "more", e)}
          style={{ color: "black" }}
        />
      </div> */}
    </div>
  );
};
export default Tabletab;
