interface IFormProps {
  email: string | undefined;
  firstName: string | undefined;
  lastName?: string | undefined;
  companyName: string | undefined;
  vendorApiSfcCompanyCode: string | undefined;
  websiteUniqueUrl?: string | undefined;
}

export function validateVendorUpdate(formData: IFormProps | undefined) {
  console.log("formData", formData);
  let isFormValid = true;
  let error: IFormProps = {
    email: "",
    firstName: "",
    companyName: "",
    vendorApiSfcCompanyCode: "",
    // websiteUniqueUrl: "",
  };

  if (formData == undefined) {
    isFormValid = false;
    error.email = "This field cannot be empty!";

    error.firstName = "This field cannot be empty!";
    error.lastName = "This field cannot be empty!";
    error.companyName = "This field cannot be empty!";
    error.vendorApiSfcCompanyCode = "This field cannot be empty!";
  }
  if (
    formData &&
    (formData?.firstName == undefined ||
      formData?.firstName == "" ||
      formData?.firstName.trim().length == 0)
  ) {
    isFormValid = false;
    error.firstName = "This field cannot be empty!";
  }

  if (
    formData &&
    (formData?.companyName == undefined ||
      formData?.companyName == "" ||
      formData?.companyName.trim().length == 0)
  ) {
    isFormValid = false;
    error.companyName = "This field cannot be empty!";
  }
  if (
    formData &&
    (formData?.vendorApiSfcCompanyCode == undefined ||
      formData?.vendorApiSfcCompanyCode == "" ||
      formData?.vendorApiSfcCompanyCode.trim().length == 0)
  ) {
    isFormValid = false;
    error.vendorApiSfcCompanyCode = "This field cannot be empty!";
  }

  // if (
  //   formData &&
  //   (formData?.websiteUniqueUrl == undefined ||
  //     formData?.websiteUniqueUrl == "" ||
  //     formData?.websiteUniqueUrl.trim().length == 0)
  // ) {
  //   isFormValid = false;
  //   error.websiteUniqueUrl = "This field cannot be empty!";
  // }

  if (
    formData &&
    (formData?.email == undefined ||
      formData?.email == "" ||
      formData?.email.trim().length == 0)
  ) {
    isFormValid = false;
    error.email = "Email cannot be empty!";
  } else if (
    formData &&
    formData.email !== undefined &&
    formData.email.trim().length > 0 &&
    /^([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})$/.test(
      formData.email
    ) === false
  ) {
    isFormValid = false;
    error.email = "Email must be a valid email address";
  }

  console.log(error, "LOGIN FROM ERR");
  return {
    isFormValid,
    error,
  };
  // }
}
