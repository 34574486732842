import { useState } from "react";

import "./Login.css";
// import Logoimg from "../../img/logo.png";
import { validateLogIn } from "./Validate";
// import { LoginIProps } from "../../services/interfaces";
import { loginAction } from "../../redux/Actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../services";

interface LoginIProps {
  email: string | undefined;
  password: string | undefined;
}

interface ValidateForm {
  isFormValid: boolean;
  error: LoginIProps;
}

const Login: React.FC = (props: any) => {
  const dispatch = useDispatch();

  const [state, setState] = useState();
  const [error, setError] = useState<LoginIProps | null>(null);

  const loading = useSelector((state: any) => {
    return state.CommonReducer.loading;
  });
  const onHandleChange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let response: ValidateForm = await validateLogIn(state);
    // console.log("response",response)
    if (response.isFormValid) {
      setState(undefined);
      await dispatch(loginAction(state, props));
    } else {
      setError(response.error);
    }
  };
  return (
    <div className="commnbg">
      <div className="formwidth loginform">
        <h1>ELEVATE </h1>
        <form>
          <h3>Sign In</h3>
          <div className="formItem">
            <div className="form-group">
              <label className="input__label">
                Email
                <input
                  className="inptField"
                  name="email"
                  placeholder="Email"
                  onChange={onHandleChange}
                ></input>
              </label>
            </div>
            {error?.email && (
              <p style={{ position: "unset" }}>{error?.email}</p>
            )}
          </div>
          <div className="formItem">
            <div className="form-group">
              <label className="input__label">
                Password
                <input
                  name="password"
                  className="inptField"
                  type="password"
                  placeholder="Enter Password"
                  onChange={onHandleChange}
                ></input>
              </label>
            </div>
            {error?.password && (
              <p style={{ position: "unset" }}>{error?.password}</p>
            )}
          </div>

          <div className="checkdiv">
            <Link to={ROUTES.ForgetPassword}>Forgot Password?</Link>
          </div>
          <button className="submitBtn" onClick={handleSubmit}>
            Sign In{" "}
            {/* {loading ? (
              <IonSpinner className="button arrbtn" name="lines-small" />
            ) : null} */}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
