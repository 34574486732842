import { withRouter } from "react-router-dom";
import "./Header.css";

const loginRoutes = ["/superadmin"];

function Header(props: any) {
  console.log("header props", props);

  return (
    <div className="header">
      <p className="title">ELEVATE </p>
      {loginRoutes.includes(props.location.pathname) ? null : <p>Dashboard</p>}
    </div>
  );
}

export default withRouter(Header);
